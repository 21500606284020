import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OraculoService } from '../../shared/oraculo.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { configurationAssets } from './../portolio-configuracao/configuration';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portolio-atual',
  templateUrl: './portolio-atual.component.html',
  styleUrls: ['./portolio-atual.component.scss']
})
export class PortolioAtualComponent implements OnInit {

  configuration = configurationAssets;
  ativosFinais: any = {};
  loading = true;

  constructor(private cdr: ChangeDetectorRef, public oraculoService: OraculoService, public router: Router, ) { }

  ngOnInit(): void {
    console.log(this.configuration);

    this.oraculoService.getConfigurationMeta().subscribe((data) => {
      if (data == null) {
        return;
      }
      this.oraculoService.getAtivosAtuais().subscribe((value) => {
        console.log('getAtivosAtuais', value);
        if (value == undefined) value = {};
        this.ativosFinais = value;
        this.loading = false;
      });
    });


  }
  get haveZero() {
    return !Object.keys(this.ativosFinais).reduce<any>((curr, key: string) => [...curr, ...this.ativosFinais[key]], []).every((item) => (item.valorAtual > 0));
  }

  onSave() {
    console.log('teste', this.ativosFinais);
    this.oraculoService.saveAtivosAtuais(this.ativosFinais)
      .subscribe((data) => {

        this.oraculoService.setStepper('atual', true)
          .subscribe((data) => {
            this.router.navigate(['/']);
          });
      });

  }
  removeAtivo(value: any) {
    delete this.ativosFinais[value];
  }
  updateAtivos(value: any) {
    this.ativosFinais = {
      ...this.ativosFinais,
      ...value,
    };
  }
}
