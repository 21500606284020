import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { LoginComponent } from './modules/login/login.component';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { InicioComponent } from './modules/inicio/inicio.component';

import { OraculoComponent } from './modules/oraculo/oraculo.component';
import { PortolioAtualComponent } from './modules/portolio-atual/portolio-atual.component';
import { PortolioMetaComponent } from './modules/portolio-meta/portolio-meta.component';
import { PortolioConfiguracaoComponent } from './modules/portolio-configuracao/portolio-configuracao.component';
import { AuthGuard } from './shared/auth-guard.service';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { CallbackComponent } from './callback/callback.component';
import { ArvoreAlocacaoComponent } from './modules/arvore-alocacao/arvore-alocacao.component';
import { CallbackFolderComponent } from './callback-folder/callback-folder.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        redirectTo: '/inicio',
        path: '',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        component: InicioComponent,
      },
      {
        path: 'login',
        component: InicioComponent
      },
      {
        path: 'oraculo',
        component: OraculoComponent,
      },
      {
        path: 'arvore-alocacao',
        component: InicioComponent,
      },
      {
        path: 'portfolio-atual',
        component: PortolioAtualComponent,
      },
      {
        path: 'portfolio-meta',
        component: PortolioMetaComponent,
      },
      {
        path: 'configurar-carteira',
        component: PortolioConfiguracaoComponent,
      },

    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'folder-token/callback/:token',
    component: CallbackFolderComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
