<app-onboarding>
    <div class="row">
        <div class="col-md-8">
            <div style="
                border-left: 4px solid #109cf1;
                padding-left: 20px;
            ">
                <h2>Estamos prontos!</h2>
                <p>O Oráculo já tem sugestões de rebalanceamento para você:</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="padding" style="min-height: 80px;" *ngIf="open==false">
                <button style="float: right; 
    color: #fff;
    background: #109cf1;" mat-flat-button color="dark" (click)="open=true">Fazer um novo aporte ou
                    retirada</button>
            </div>
        </div>
    </div>



    <div *ngIf="open==true" class="blue-card">
        <div class="row">
            <div class="col-md-4">
                <div style="
    float: left;
    margin-bottom: -23px;
">
                    <mat-form-field appearance="fill" color="primary">
                        <mat-label>Novo aporte ou retirada:</mat-label>
                        <input [formControl]="novoAporte" type="number" matInput placeholder="100,00">
                        <mat-icon matSuffix>add</mat-icon>
                    </mat-form-field>
                </div>
                <div style="
    float: left;
    margin: 12px;
">
                    <button (click)="novoAporteSubmit()" mat-flat-button color="dark">Calcular</button>
                </div>

                <img src="assets/images/tooltip.svg" style="width: 30px; margin: 13px;"
                    matTooltip="Se for fazer uma retirada para simular coloque sinal negativo ex. - 42." />
            </div>
            <div *ngIf="loadingCalculo" class="col-md-8">
                <!--<mat-spinner style="float: left; 
    margin-right: 22px;" diameter="50" color="white"></mat-spinner>-->
                <h4 style="font-weight: bold; color: #fff; padding-top: 12px;">
                    O oráculo está calculando o seu rebalanceamento
                    <img src="assets/images/warren_Buffet_logo.png" style="width: 60px; margin-left: 5px;" />
                </h4>
            </div>
        </div>
    </div>
    <div *ngIf="!loadingCalculo">
        <div *ngIf="open==true" class="row" style="margin-top: -12px;">
            <div class="col-md-3" style="padding: 12px 12px;">
                Patrimônio investido na carteira atual:
            </div>
            <div class="col-md-2" style="padding: 12px 12px;">
                <b>{{data.valorTotal | currency:'BRL':true:'1.2-2'}}</b>
            </div>
        </div>
        <div *ngIf="open==true" class="row" style="margin-bottom: 12px;">
            <div class="col-md-3" style="padding: 12px 12px;">
                Patrimônio investido após novo aporte ou retirada:
            </div>
            <div class="col-md-2" style="padding: 12px 12px;">
                <b>{{data.valorTotal + novoAporte.value | currency:'BRL':true:'1.2-2'}}</b>
            </div>
        </div>
        <div class="card-ed">
            <div class="heading-ed">
                <div class="left"></div>
                <h5 class="sub-title">Confira o resultado do Oráculo para seu rebalanceamento:</h5>
            </div>
            <div class="scroll-tab">
                <table class="table">
                    <thead>
                        <tr
                            style="background: #ffffff;color: #000;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;font-size: 10px;position: relative;">
                            <td class="first" style="font-size: 12px">Categoria</td>
                            <td style="font-size: 12px">Valor Investido</td>
                            <td style="font-size: 12px">Carteira (%)</td>
                            <td style="font-size: 12px">Objetivo (%)</td>
                            <td style="font-size: 12px">Diferença (%)</td>
                            <td style="font-size: 12px">Objetivo</td>
                            <td style="min-width: 100px;font-size: 12px">Sugestões</td>
                            <td style="font-size: 12px">Comprar/Vender</td>
                        </tr>
                    </thead>

                    <ng-container *ngFor="let child of configuration.children">
                        <ng-container *ngVar="buildValue([child.name], data, valorWithNewAporte) as variable">
                            <ng-container *ngIf="variable.percentageMeta.percentage > 0 || variable.valueAtual > 0">
                                <thead>
                                    <tr style="color: #fff;" [style.background]="color[child.name]">
                                        <td class="first" style="min-width: 240px;">
                                            <b>
                                                {{child.name}}</b>
                                        </td>
                                        <td>{{variable.valueAtual | currency:'BRL':true:'1.2-2'}}</td>
                                        <td>{{variable.percentageCarteira | percent:'1.2'}}</td>
                                        <td>
                                            {{ variable.percentageMeta.percentageOnItem |percent:'1.2'}}
                                        </td>
                                        <td>{{variable.diferencaOnItem | percent:'1.2'}}</td>
                                        <td>{{variable.objetivo | currency:'BRL':true:'1.2-2' }}</td>
                                        <td style="background-color: #00000005; color: #b50707; font-weight: bold;"
                                            *ngIf="variable.falta < 0">Vender</td>
                                        <td style="background-color: #00000005; font-weight: bold;"
                                            *ngIf="variable.falta > 0">Comprar</td>
                                        <td style="background-color: #00000005; font-weight: bold;"
                                            *ngIf="variable.falta == 0">OK</td>
                                        <td style="background-color: #00000005; font-weight: bold;">
                                            {{variable.falta | currency:'BRL':true:'1.2-2'}}</td>

                                    </tr>
                                </thead>
                            </ng-container>
                            <ng-container *ngFor="let childTwo of child.children">
                                <ng-container *ngVar="buildValue([child.name, childTwo.name], data, valorWithNewAporte) as variable">
                                    <ng-container
                                        *ngIf="variable.percentageMeta.percentage > 0 || variable.valueAtual > 0">
                                        <thead>
                                            <tr [style.background]="color[child.name] + '15'">
                                                <td class="first" style="cursor: pointer;"
                                                    (click)="childTwo.open = ! childTwo.open">
                                                    <mat-icon *ngIf="childTwo.children"
                                                        style="float: left;margin-right: 12px;">
                                                        keyboard_arrow_right
                                                    </mat-icon>
                                                    <b> {{childTwo.name}}</b>
                                                </td>
                                                <td>{{variable.valueAtual| currency:'BRL':true:'1.2-2'}}</td>
                                                <td>{{variable.percentageCarteira |percent:'1.2'}}
                                                </td>

                                                <td>
                                                    {{ variable.percentageMeta.percentageOnItem |percent:'1.2'}}

                                                </td>
                                                <td>{{variable.diferencaOnItem |percent:'1.2'}}</td>
                                                <td>{{variable.objetivo| currency:'BRL':true:'1.2-2'}}</td>
                                                <td style="background-color: #00000005; color: #b50707; font-weight: bold;"
                                                    *ngIf="variable.falta < 0">
                                                    Vender
                                                </td>
                                                <td style="background-color: #00000005; color: #249a29; font-weight: bold;"
                                                    *ngIf="variable.falta > 0">
                                                    Comprar
                                                </td>
                                                <td style="background-color: #00000005; font-weight: bold;"
                                                    *ngIf="variable.falta == 0">OK
                                                </td>
                                                <td style="background-color: #00000005; font-weight: bold;">
                                                    {{variable.falta| currency:'BRL':true:'1.2-2'}}</td>

                                            </tr>
                                        </thead>
                                        <ng-container *ngIf="childTwo.open">
                                            <ng-container *ngFor="let childTree of childTwo.children">
                                                <ng-container
                                                    *ngVar="buildValue([child.name, childTwo.name, childTree.name], data, valorWithNewAporte) as variable">
                                                    <ng-container
                                                        *ngIf="variable.percentageMeta.percentage > 0 || variable.valueAtual > 0">
                                                        <tbody *ngIf="childTree.children == undefined ">
                                                            <tr style="">
                                                                <td class="first">
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;{{childTree.name}}
                                                                </td>
                                                                <td>{{variable.valueAtual| currency:'BRL':true:'1.2-2'}}
                                                                </td>
                                                                <td>{{variable.percentageCarteira |percent:'1.2'}}
                                                                </td>

                                                                <td>
                                                                    {{ variable.percentageMeta.percentageOnItem
                                                                    |percent:'1.2'}}

                                                                </td>
                                                                <td>{{variable.diferencaOnItem |percent:'1.2'}}</td>
                                                                <td>{{variable.objetivo| currency:'BRL':true:'1.2-2'}}
                                                                </td>
                                                                <td style="background-color: #00000005;color: #b50707;  font-weight: bold;"
                                                                    *ngIf="variable.falta < 0">Vender</td>
                                                                <td style="background-color: #00000005; color: #249a29; font-weight: bold;"
                                                                    *ngIf="variable.falta > 0">Comprar</td>
                                                                <td style="background-color: #00000005; font-weight: bold;"
                                                                    *ngIf="variable.falta == 0">OK</td>
                                                                <td
                                                                    style="background-color: #00000005; font-weight: bold;">
                                                                    {{variable.falta| currency:'BRL':true:'1.2-2'}}</td>

                                                            </tr>
                                                        </tbody>
                                                        <thead *ngIf="childTree.children != undefined  ">
                                                            <tr [style.background]="color[child.name] + '05'">
                                                                <td class="first">
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;{{childTree.name}}
                                                                </td>
                                                                <td>{{variable.valueAtual| currency:'BRL':true:'1.2-2'}}
                                                                </td>
                                                                <td>{{variable.percentageCarteira |percent:'1.2'}}
                                                                </td>

                                                                <td>
                                                                    {{ variable.percentageMeta.percentageOnItem
                                                                    |percent:'1.2'}}

                                                                </td>
                                                                <td>{{variable.diferencaOnItem |percent:'1.2'}}</td>
                                                                <td>{{variable.objetivo| currency:'BRL':true:'1.2-2'}}
                                                                </td>
                                                                <td style="background-color: #00000005;color: #b50707;  font-weight: bold;"
                                                                    *ngIf="variable.falta < 0">Vender</td>
                                                                <td style="background-color: #00000005; color: #249a29; font-weight: bold;"
                                                                    *ngIf="variable.falta > 0">Comprar</td>
                                                                <td style="background-color: #00000005; font-weight: bold;"
                                                                    *ngIf="variable.falta == 0">OK</td>
                                                                <td
                                                                    style="background-color: #00000005; font-weight: bold;">
                                                                    {{variable.falta| currency:'BRL':true:'1.2-2'}}</td>

                                                            </tr>
                                                        </thead>
                                                    </ng-container>
                                                    <ng-container *ngIf="childTree.children != undefined">
                                                        <ng-container *ngFor="let childFour of childTree.children">
                                                            <ng-container
                                                                *ngVar="buildValue([child.name, childTwo.name, childTree.name, childFour.name], data, valorWithNewAporte) as variable">
                                                                <ng-container
                                                                    *ngIf="variable.percentageMeta.percentage > 0 || variable.valueAtual > 0">
                                                                    <tbody>
                                                                        <tr style="">
                                                                            <td class="first">
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{childFour.name}}
                                                                            </td>
                                                                            <td>{{variable.valueAtual|
                                                                                currency:'BRL':true:'1.2-2'}}
                                                                            </td>
                                                                            <td>
                                                                                {{variable.percentageCarteira
                                                                                |percent:'1.2'}}
                                                                            </td>

                                                                            <td>
                                                                                {{
                                                                                variable.percentageMeta.percentageOnItem
                                                                                |percent:'1.2'}}

                                                                            </td>
                                                                            <td>{{variable.diferencaOnItem
                                                                                |percent:'1.2'}}
                                                                            </td>
                                                                            <td>{{variable.objetivo|
                                                                                currency:'BRL':true:'1.2-2'}}
                                                                            </td>
                                                                            <td style="background-color: #00000005;color: #b50707;  font-weight: bold;"
                                                                                *ngIf="variable.falta < 0">Vender</td>
                                                                            <td style="background-color: #00000005; color: #249a29; font-weight: bold;"
                                                                                *ngIf="variable.falta > 0">Comprar</td>
                                                                            <td style="background-color: #00000005; font-weight: bold;"
                                                                                *ngIf="variable.falta == 0">OK</td>
                                                                            <td
                                                                                style="background-color: #00000005; font-weight: bold;">
                                                                                {{variable.falta|
                                                                                currency:'BRL':true:'1.2-2'}}
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
    <br><strong>Disclaimer:</strong> Esta não é uma recomendação de compra e vendas de ativos. Ao apontar as sugestões
    de compra ou venda, o Oráculo considera as ações necessárias para alcance do balanceamento ideal de acordo com o seu
    portfólio meta.<br>
    No entanto, a execução das ações de rebalanceamento podem ocorrer gradualmente, através de novos aportes, não apenas
    da venda de ativos.

    <div *ngIf="origin=='empreenderdinheiro'" routerLink="/">
        <div class="alert alert-primary" role="alert" style="    margin-top: 20px;
    color: #fff;
    background-color: #363636;
    border-color: #363636;">
            <h4 style="    font-size: 18px;
    font-weight: bold;
    color: #fff;">Gostaria de ser atendido por um dos nossos consultores especializados?</h4>
            <p>
                Eles podem te ajudar a ajustar seu portfólio de forma segura e otimizada. <br>
                Por ser usuário do Oráculo você conta com um desconto especial.
            </p>
            <a mat-flat-button color="accent"
                href="https://api.whatsapp.com/send?phone=5581996838285&text=Olá venho do Oráculo e gostaria de atendimento especial"
                style="text-decoration: none;">
                <mat-icon> chat</mat-icon> Quero mais informações
            </a>
        </div>
    </div>
    <div *ngIf="origin=='techfinance'" routerLink="/">
    </div>
</app-onboarding>