import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

import HC_sunburst from 'highcharts/modules/sunburst';
import { OraculoService } from '../../shared/oraculo.service';
import { map, filter } from 'rxjs/operators';
import { buildChartData } from './utils';
import { colors } from './../portolio-configuracao/configuration';
import { ChartOptions } from 'chart.js';
import { FormControl } from '@angular/forms';
import { ultimateReportMap } from '../../shared/ultimate-report-map';
import { ReportsService } from './reports.service';

HC_sunburst(Highcharts);

@Component({
	selector: 'app-inicio',
	templateUrl: './inicio.component.html',
	styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
	configuration: any;
	valorTotal: any;
	valorTotalFormControl: FormControl = new FormControl();
	loadingDownload = false;
	public pieChartLegend = true;
	public pieChartPlugins = [];
	public pieChartColors = [
		{
			backgroundColor: ['#00c08e', '#00ccff'],
		},
	];
	public rendaFixaColor = [
		{
			backgroundColor: ['#00ccff', '#00ccff', '#00ccff', '#00ccff', '#00ccff', '#00ccff', '#00ccff', '#00ccff'],
		},
	];
	public rendaVariavelColor = [
		{
			backgroundColor: ['#00c08e', '#00c08e', '#00c08e', '#00c08e', '#00c08e', '#00c08e', '#00c08e', '#00c08e'],
		},
	];
	colors: any = colors;

	public pieChartOptions: ChartOptions = {
		responsive: false,
		legend: {
			position: 'top',
		},
		tooltips: {
			mode: 'x-axis',
			bodyFontColor: '#000',
			backgroundColor: '#FFF',
			bodyFontFamily: 'Red Hat Display',
			titleFontColor: '#109cf1',
			callbacks: {
				title: (i: any, data) => {
					return ['Renda Fixa', 'Renda Váriavel'][i[0].index].toString();
				},
				label: (i, data) => {
					let number = Number(data.datasets[0].data[i.index]);
					let value = Number(number).toLocaleString('pt-BR', {
						minimumIntegerDigits: 1,
						minimumFractionDigits: 0,
						maximumFractionDigits: 2,
					});
					return value + ' %';
				},
			},
		},
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					const label = ctx.chart.data.labels[ctx.dataIndex];
					return label + '%';
				},
			},
		}
	};
	public pieChartMoneyOptions: ChartOptions = {
		responsive: false,
		legend: {
			position: 'top',
		},
		tooltips: {
			mode: 'x-axis',
			bodyFontColor: '#000',
			backgroundColor: '#FFF',
			bodyFontFamily: 'Red Hat Display',
			titleFontColor: '#109cf1',
			callbacks: {
				title: (i: any, data) => {
					return data.labels[i[0].index].toString();
				},
				label: (i, data) => {
					let number = Number(data.datasets[0].data[i.index]);
					let value = Number(number).toLocaleString('pt-BR', {
						minimumIntegerDigits: 1,
						minimumFractionDigits: 0,
						maximumFractionDigits: 2,
					});
					return 'R$' + value;
				},
			},
		},
	};
	public pieChartPercentageOptions: ChartOptions = {
		responsive: false,
		legend: {
			position: 'top',
		},
		tooltips: {
			mode: 'x-axis',
			bodyFontColor: '#000',
			backgroundColor: '#FFF',
			bodyFontFamily: 'Red Hat Display',
			titleFontColor: '#109cf1',
			callbacks: {
				title: (i: any, data) => {
					return data.labels[i[0].index].toString();
				},
				label: (i, data) => {
					let number = Number(data.datasets[0].data[i.index]);
					let value = Number(number).toLocaleString('pt-BR', {
						minimumIntegerDigits: 1,
						minimumFractionDigits: 0,
						maximumFractionDigits: 2,
					});
					return value + '%';
				},
			},
		},
	};

	public barChartPlugins = [];
	chartRendaFixaData: any;
	chartRendaVariavelData: any;

	carteiraAtual: any = [];
	carteiraAtualPercentage: any = [];


	carteiraAtualRendaFixa: any;
	carteiraAtualRendaVariavel: any;
	metaRendaFixa: any;
	metaRendaVariavel: any;

	downloadData: any;

	constructor(
		public oraculoService: OraculoService,
		public reportsService: ReportsService,
	) {
		this.oraculoService.verifySteppers(['atual', 'carteira'])
			.subscribe((data) => {
				if (data == true) {
					this.oraculoService.getOraculoInformation()
						.pipe(
							filter((value) => value != null),
							map((data) => {
								this.downloadData = data;
								this.valorTotal = data.valorTotal;
								this.valorTotalFormControl.setValue(this.createValueWithMask(data.valorTotal));
								this.configuration = data.configuration;
								this.chartRendaFixaData = this.chartData(this.configuration.children[0]);
								this.chartRendaVariavelData = this.chartData(this.configuration.children[1]);

								let child = JSON.parse(JSON.stringify(this.configuration));
								child.children[0].children = child.children[0].children
									.map((item) => {
										item.value = this.getValueAtual([this.configuration.children[0].name, item.name], data);
										return item;
									});
								child.children[1].children = child.children[1].children
									.map((item) => {
										item.value = this.getValueAtual([this.configuration.children[1].name, item.name], data);
										return item;
									});
								this.carteiraAtual.push(
									this.getValueAtual([this.configuration.children[0].name], data),

									this.getValueAtual([this.configuration.children[1].name], data)
								);
								this.carteiraAtualPercentage = this.carteiraAtual.map((value) => (value / this.valorTotal * 100));

								//Graficos sengudarios

								this.carteiraAtualRendaFixa = this.generateChart(child.children[0], data);
								this.carteiraAtualRendaVariavel = this.generateChart(child.children[1], data);

								this.metaRendaFixa = this.generateChartMeta(this.configuration.children[0], data);
								this.metaRendaVariavel = this.generateChartMeta(this.configuration.children[1], data);

								return buildChartData(data.configuration);
							})
						)
						.subscribe((data) => { });
				}
			});

	}
	get labelsMetaChart() {
		return [
			'Renda Fixa (' + (this.configuration?.children[0]?.value ?? 0).toFixed(2) + '%)', 'Renda Váriavel(' + (this.configuration?.children[1]?.value ?? 0).toFixed(2) + '%)'
		];
	}
	get labelsFirstChart() {
		return [
			'Renda Fixa (' + (this.carteiraAtualPercentage[0] ?? 0).toFixed(2) + '%)', 'Renda Váriavel(' + (this.carteiraAtualPercentage[1] ?? 0).toFixed(2) + '%)'
		];
	}
	get valueTotalControl() {
		return Number(this.valorTotalFormControl.value.toString().replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace(',', '.'));
	}
	private createValueWithMask(value): string {
		let intl = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
		return (intl.format(value) as any).replace('R$ ');
	}
	generateChart(child, data) {
		let children = child.children;
		let totalValue = child.children.reduce((prev, item) => prev + (item.value ?? 0), 0);
		return {
			labels: children
				.map((item) => {

					let value = (((item.value ?? 0) / totalValue) * 100);
					return item.name + ' (' + (!Number.isNaN(value) ? value : 0).toFixed(2) + '%)';
				}),
			data: children
				.map((item) => {
					return this.getValueAtual([child.name, item.name], data);
				}),
			colors: [
				{
					backgroundColor: children
						.map((item, i) => {
							return ['#00b0f0', '#00cc99', '#ed8b0b', '#efbc64', '#ca0023'][i];
						}),
				},
			],
		};
	}
	generateChartMeta(child, data) {
		let children = child.children;
		return {
			labels: children
				.map((item) => {
					return item.name + ' (' + (item.value ?? 0).toFixed(2) + '%)';

				}),
			data: children
				.map((item) => {
					return item.value;
				}),
			colors: [
				{
					backgroundColor: children
						.map((item, i) => {
							return ['#00b0f0', '#00cc99', '#ed8b0b', '#efbc64', '#ca0023'][i];
						}),
				},
			],
		};
	}

	chartData(child) {
		return [
			{ data: child.children.map((item) => item.value), label: 'Renda Fixa' },

		];
	}
	chartDataLabel(child) {
		return child.children.map((item) => item.name);
	}
	downloadAction() {
		this.loadingDownload = true;
		this.reportsService.getFolderInfo()
			.subscribe((users: any) => {
				const data = ultimateReportMap(this.downloadData);
				data.informations = {
					name: users.folderUser.firstName + ' ' + users.folderUser.lastName,
					consultant: users.folderUser.firstName + ' ' + users.folderUser.lastName,
					date: new Date(),
					"emissionDate": {
						"date": "ME ALTERA PRA date",
						"time": "18:33"
					}
				};
				this.reportsService.downloadOraculoReport(data)
					.subscribe((data) => {
						this.loadingDownload = false;
						var a = document.createElement("a"); //Create <a>
						a.href = "data:image/png;base64," + data; //Image Base64 Goes here
						a.download = "oraculo.pdf"; //File name Here
						a.click(); //Downloaded file
					});
			});
	}

	getValueAtual(keys, data) {
		return Object.keys(data.atuais)
			.map((key) => key.split('_'))
			.filter((keysList) => (keysList.filter((item, i) => i < keys.length) as any).equals(keys))
			.reduce((prevMain, keys: any) => {
				return data.atuais[keys.join('_')].reduce((prev, item) => prev + item.valorAtual, prevMain);
			}, 0);
	}

	ngOnInit(): void {
	}

}
