import { Component, OnInit } from '@angular/core';
import { ModalStartComponent } from '../../../modal-start/modal-start.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { AuthFolderService } from 'src/app/callback-folder/auth-folder.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(public dialog: MatDialog, public authService: AuthService, private authFolderService: AuthFolderService,) {
    this.origin = this.getOrigin();
  }

  origin: string = 'empreenderdinheiro';
  baseUrl: string = environment.baseUrl;
  accessToken: string;

  ngOnInit() {}

  get activeFolder (): boolean {
    return this.authFolderService.authenticated();
  }
  backFolder() {
    delete localStorage.folderToken;
    location.href =  environment.url.replace('https://', 'https://techfinance.').replace('.api.', '.');
  }

  getOrigin(): string {    
    if ((location as any).href.toString().split('tf').length > 1) {
      return 'techfinance';
    }
    if ((location as any).href.toString().split('techfinance').length > 1) {
      return 'techfinance';
    }
    return 'empreenderdinheiro';
  }

  openModal() {
    console.log('teste');
    let dialogRef = this.dialog.open(ModalStartComponent, {
      height: '470px',
      width: '620px',
    });
  }
  get isAdmin() {
    return localStorage.admin;
  }
  rebalanciamentoBeta() {
    alert('Ferramenta disponível em breve.');
  }
}
