<div style="padding: 0px 12px;">
	<h2>
		Portfólio Meta:
	</h2>
	<ng-template #selectorDefault let-child='child'>
		<div class="row">
			<div *ngFor="let item of child.get('children')?.controls" class="col-md-6">
				<div class="card-decision ">

					<h3 *ngIf="item.get('disclaimer').value" [style.color]="item.get('color').value"
						[style.borderColor]="item.get('color').value"
						[matTooltip]="item.get('disclaimer').value.toString()">
						{{item.get('name').value}} <span content-type="template">
							<svg width="17" height="17" viewBox="0 0 17 17" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
									fill="black" />
							</svg>
						</span>
					</h3>
					<h3 *ngIf="!item.get('disclaimer').value" [style.color]="item.get('color').value"
						[style.borderColor]="item.get('color').value">
						{{item.get('name').value}}</h3>
					<percentage-editor [control]="item.get('value')"></percentage-editor>
					<span style="
    color: #666;
    font-size: 12px;
    line-height: 18px;
">{{item.get('aviso')?.value}}</span>
				</div>
			</div>
		</div>
		<div *ngIf="child.touched && child?.errors?.totalEqual100==true" class="alert alert-danger" role="alert">
			A soma deve ser equivalente a 100%.
		</div>

		<div style="text-align: right;padding-top: 16px;">
			<button (click)="nextStep()" mat-flat-button style="background-color: #109cf1;"
				[disabled]="child?.errors?.totalEqual100==true" color="accent">Próximo
			</button>
		</div>
	</ng-template>

	<ng-template #selectorWithChildrens let-child='child' let-secondTitle="secondTitle">
		<div class="row">
			<div *ngFor="let item of child.get('children')?.controls; let iD = index" class="col-md-6">
				<div class="card-decision ">
					<h3 *ngIf="item.get('disclaimer').value" [style.color]="item.get('color').value"
						[style.borderColor]="item.get('color').value"
						[matTooltip]="item.get('disclaimer').value.toString()">
						{{item.get('name').value}} <span content-type="template">
							<svg width="17" height="17" viewBox="0 0 17 17" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
									fill="black" />
							</svg>
						</span>
					</h3>
					<h3 *ngIf="!item.get('disclaimer').value" [style.color]="item.get('color').value"
						[style.borderColor]="item.get('color').value">
						{{item.get('name').value}}</h3>
					<percentage-editor [control]="item.get('value')"></percentage-editor>

				</div>
				<div class="card-decision ">
					<h3 [style.color]="item.get('color').value" [style.borderColor]="item.get('color').value">

						<span *ngIf="secondTitle== undefined">
							2. Ativos Balanceados:
						</span>

						<span *ngIf="secondTitle!= undefined">
							{{secondTitle.fill != undefined ? secondTitle[iD]: secondTitle}}
						</span>
					</h3>

					<div *ngFor="let itemChild of item.get('children')?.controls" class="item">
						<h4 style="font-weight: bold;">{{itemChild.get('name').value}}</h4>
						<percentage-editor [control]="itemChild.get('value')"></percentage-editor>
					</div>
					<div *ngIf="item.touched && i?.errors?.totalEqual100==true" class="alert alert-danger" role="alert">
						A soma deve ser equivalente a 100%.
					</div>

				</div>

			</div>
		</div>
		<div *ngIf="child.touched && child?.errors?.totalEqual100==true" class="alert alert-danger" role="alert">
			A soma deve ser equivalente a 100%.
		</div>
		<div style="text-align: right;padding-top: 16px;">
			<button (click)="nextStep()" mat-flat-button style="background-color: #109cf1;" [disabled]="!child.valid"
				color="accent">Próximo
			</button>
		</div>
	</ng-template>



	<div class="row">
		<div class="col-md-12">
			<mat-accordion class="example-headers-align">
				<mat-expansion-panel [expanded]="step === 0" [attr.id-item]="0">
					<mat-expansion-panel-header (click)="setPanel($event, 0)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon *ngIf="step>0">check</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								<!-- E 6 linhas pra baixo fica os resto dos textos  -->
								Alocação principal
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Quanto você quer investir em Renda Fixa e Renda Variável?
					</h4>
					<p>
						Siga para as próximas etapas escolhendo o tipo de produto no menu abaixo. Ao concluir o
						preenchimento, você terá acesso à árvore de decisão e dashboards.
					</p>
					<ng-container *ngTemplateOutlet="selectorDefault; context: {child: configurationGroup}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel *ngIf="configurationGroup?.controls?.children?.controls[0].value.value > 0"
					[expanded]="step === 1" [attr.id-item]="1">
					<mat-expansion-panel-header (click)="setPanel($event, 1)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon *ngIf="configurationGroup?.controls?.children?.controls[0].valid || step>1">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Renda Fixa
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Agora vamos detalhar melhor seu investimento em renda fixa? <img src="assets/images/tooltip.svg"
							style="width: 20px; margin-left: 5px;"
							matTooltip="Sua alocação em renda fixa irá contribuir para reduzir a volatilidade da carteira, proporcionando mais segurança e maior liquidez.
                        Como referência, alocações abaixo de 20% nesta categoria não são adequadas ao investidor individual pessoa física comum (PFC), em caso de dúvida consulte no menu Estratégias de Referência." />
						<p><i>A soma dos percentuais deve ser igual a 100%.</i></p>


					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorDefault; context: {child: configurationGroup?.controls?.children?.controls[0]}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel *ngIf="configurationGroup?.controls?.children?.controls[1].value.value > 0"
					[expanded]="step === 2" [attr.id-item]="2">
					<mat-expansion-panel-header (click)="setPanel($event, 2)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon *ngIf="configurationGroup?.controls?.children?.controls[1].valid|| step>2">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Renda Váriavel
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Agora vamos detalhar melhor seu investimento em renda variável?
						<img src="assets/images/tooltip.svg" style="width: 20px; margin-left: 5px;"
							matTooltip="Sua alocação em renda variável irá contribuir para aumentar a rentabilidade da sua carteira. Como referência, alocações superiores a 70% nesta categoria são consideradas de perfil agressivo, para investidores individuais." />
						<p><i>A soma dos percentuais deve ser igual a 100%.</i></p>

					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorDefault; context: {child: configurationGroup?.controls?.children?.controls[1]}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel
					*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[0].value.value > 0"
					[expanded]="step === 3" [attr.id-item]="3">
					<mat-expansion-panel-header (click)="setPanel($event, 3)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon
									*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[0].valid || step>3">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Ações
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Vamos distribuir o seu investimento em Ações? Escolha entre ações brasileiras e ações
						internacionais:
						<img src="assets/images/tooltip.svg" style="width: 20px; margin-left: 5px;"
							matTooltip="Sua alocação em ações irá contribuir para aumentar a rentabilidade da sua carteira. Como referência, lembre-se que é adequado para o investidor individual médio ter mais de 50% do percentual de renda variável distribuído entre ações e imóveis." />
						<p><i>A soma dos percentuais deve ser igual a 100%</i></p>
					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorWithChildrens; context: {secondTitle: ['2. Como você quer distribuir Ações Brasil?', '2. Como você quer distribuir Ações Internacional?'], child: configurationGroup?.controls?.children?.controls[1].controls.children.controls[0]}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel
					*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[1].value.value > 0"
					[expanded]="step === 4" [attr.id-item]="4">
					<mat-expansion-panel-header (click)="setPanel($event, 4)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon
									*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[1].valid || step>4">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Imóveis
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Agora vamos distribuir o seu investimento em Imóveis? Escolha entre imóveis brasileiros e
						imóveis no exterior:
						<img src="assets/images/tooltip.svg" style="width: 20px; margin-left: 5px;"
							matTooltip="Sua alocação em imóveis irá contribuir para aumentar a rentabilidade da sua carteira.
                        Como referência, lembre-se que é adequado para o investidor individual médio ter mais de 50% do percentual de renda variável distribuído entre ações e imóveis" />
						<p><i>A soma dos percentuais deve ser igual a 100%</i></p>
					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorWithChildrens; context: {secondTitle: ['2. Como você quer distribuir Imóveis Brasil?', '2. Como você quer distribuir Imóveis Exterior?'], child: configurationGroup?.controls?.children?.controls[1].controls.children.controls[1]}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel
					*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[2].value.value > 0"
					[expanded]="step === 5" [attr.id-item]="5">
					<mat-expansion-panel-header (click)="setPanel($event, 5)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon
									*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[2].valid || step>5">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Proteção
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Sobre os ativos de proteção, como será a sua distribuição?
						<img src="assets/images/tooltip.svg" style="width: 20px; margin-left: 5px;" matTooltip="Sua alocação em ativos de proteção irá contribuir para descorrelacionar sua carteira, tornando-a mais resistente a momentos de instabilidade.
                        Como referência, alocações de até 10% nesta categoria são consideradas adequadas para investidores individuais.
                        " />
						<p><i>A soma dos percentuais deve ser igual a 100%</i></p>

					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorDefault; context: {child: configurationGroup?.controls?.children?.controls[1].controls.children.controls[2]}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel
					*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[3].value.value > 0"
					[expanded]="step === 6" [attr.id-item]="6">
					<mat-expansion-panel-header (click)="setPanel($event, 6)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon
									*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[3].valid || step>6">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Criptoativos
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Sobre a alocação em Criptoativos, como será a sua distribuição?
						<img src="assets/images/tooltip.svg" style="width: 20px; margin-left: 5px;" matTooltip="Como investimento, as criptomoedas podem contribuir para turbinar sua rentabilidade. Contudo, ressalta-se a característica de volatilidade destes ativos.
                        Como referência, alocações de até 5% nesta categoria são consideradas adequadas para investidores individuais.
                        " />
						<p><i>A soma dos percentuais deve ser igual a 100%</i></p>

					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorDefault; context: {child: configurationGroup?.controls?.children?.controls[1].controls.children.controls[3]}">
					</ng-container>


				</mat-expansion-panel>
				<mat-expansion-panel
					*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[4].value.value > 0"
					[expanded]="step === 7" [attr.id-item]="7">
					<mat-expansion-panel-header (click)="setPanel($event, 7)">
						<mat-panel-title>
							<div style="font-weight: bold;">
								<mat-icon
									*ngIf="configurationGroup?.controls?.children?.controls[1].controls.children.controls[4].valid || step>7">
									check
								</mat-icon>
								<!-- AQUI charlão fica os textos da categoria:  -->
								Fundos Multimercado e Outros
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<h4 class="title-text">
						Como você pretende distribuir seus investimentos em outros fundos?
						<img src="assets/images/tooltip.svg" style="width: 20px; margin-left: 5px;" matTooltip="Use esta categoria para acessar fundos multimercados, fundos temáticos e derivativos.
                        Em tempo, destacamos que o uso de derivativos é pouco adequado para investidores individuais em geral.
                        " />
						<p><i>A soma dos percentuais deve ser igual a 100%</i></p>

					</h4>
					<p>
						Por favor, preencha ou selecione abaixo:
					</p>
					<ng-container
						*ngTemplateOutlet="selectorDefault; context: {child: configurationGroup?.controls?.children?.controls[1].controls.children.controls[4]}">
					</ng-container>


				</mat-expansion-panel>

			</mat-accordion>
			<div class="row">
				<div class="col-md-12">
					<button (click)="onSave()" mat-flat-button
						style="background-color: #05b31c;float: right;padding: 4px 18px;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;font-size: 16px;margin-top: 16px;"
						[disabled]="!configurationGroup.valid || step < 1" color="accent">Finalizar
					</button>
					<div style="float: right;padding: 24px;font-size: 14px;color: #000;font-weight: normal;">Clique ao
						lado para confirmar seu portfolio meta:</div>

				</div>
			</div>

		</div>
	</div>
