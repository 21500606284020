export let colors = {
	"Renda Fixa": "#00ccff",
	"Produtos": "#00c08e",
	"PRÉ FIXADO & Tesouro": "#000",
	"PÓS FIXADO & Tesouro": "#000",
	"Híbrido NTNB": "#000",
	"Previdência Privada": "#000",
	"Renda Fixa Previdência Privada": "#1338d1",
	"Renda Váriavel": "#00c08e",
	"Ações": "#000",
	"Brasil": "#000",
	"Fundos de Ações e Investimentos": "#000",
	"ETFs": "#000",
	"Carteira Própria": "#000",
	"Internacional": "#000",
	"ETFS Internacionais": "#000",
	"ETFs Locais": "#000",
	"Stocks & BDRs": "#000",
	"Fundos Multimercado": "#000",
	"Imóveis": "#000",
	"Fundos Imobiliários": "#000",
	"ETFs Internacionais": "#000",
	"REITs": "#000",
	"Proteção": "#ed8b0b",
	"Dolár": "#ed8b0b",
	"Ouro": "#ed8b0b",
	"Outras Moedas  % Metais": "#ed8b0b",
	"Criptoativos": "#efbc64",
	"Fundos de Criptomoedas": "#efbc64",
	"Fundos MMs & Outros": "#000",
	"Fundo Multimercado": "#000",
	"Fundos Temáticos": "#000",
	"Renda Variável Previdência Privada": "#000"
};
export let configurationAssets = {
	name: 'Árvore de Decisão', children: [
		{
			name: 'Renda Fixa',
			aviso: 'Ao alocar todo o seu patrimônio em ativos de renda fixa, você poderá perder boas oportunidades de aumentar a rentabilidade da sua carteira através da renda variável',
			color: '#1338d1',
			children: [
				{
					name: 'Pós fixado',
					color: '#3050c9',
					disclaimer: 'Os títulos pós-fixados são ideais para assegurar liquidez imediata. Fundos DI e os títulos públicos da categoria “Tesouro Selic” são alternativas de títulos de renda fixa pós-fixados. Em geral, são adequados para objetivos de curto prazo. ',
				},

				{
					name: 'Prefixados',
					color: '#308fb3',
					disclaimer: 'Os títulos prefixados asseguram uma rentabilidade preestabelecida no momento da aplicação. Alguns CDBs, LCIs, LCAs e os próprios títulos públicos da categoria “tesouro prefixado” são alternativas de títulos de renda fixa prefixados. Em geral, eles são adequados para objetivos de médio prazo.',
				},

				{
					name: 'Híbridos (Inflação)',
					color: '#2c8f22',
					disclaimer: 'Os títulos híbridos asseguram um retorno real, ou seja, acima da inflação. Debêntures e títulos públicos da categoria “tesouro IPCA+” são alternativas de títulos de renda fixa híbridos. Em geral, são adequados para objetivos de longo prazo.',

				},
				{
					name: 'Previdência Privada',
					color: '#34212d',
					disclaimer: 'Visando simplificar a construção da estratégia, entendendo que se trata de um produto com características previdenciárias e protetivas para beneficiários e dependentes, a previdência privada está inserida junto aos produtos de renda fixa.',
				},
			]
		},
		{
			name: 'Renda Váriavel',
			color: '#00926c',
			aviso: 'Ao alocar mais de 70% do seu patrimônio em ativos de renda variável, você poderá expor sua carteira a um risco e volatilidade maiores que os recomendáveis para investidores PFCs (pessoas físicas comuns).',
			children: [
				{
					name: 'Ações',
					color: '#00b0f0',
					disclaimer: 'Sua alocação em ações irá contribuir para aumentar a rentabilidade da sua carteira. Como referência, lembre-se que é adequado para o investidor individual médio ter mais de 50% do percentual de renda variável distribuído entre ações e imóveis.',
					children: [
						{
							name: 'Brasil',
							color: '#00926c',
							disclaimer: 'Como referência, é inteligente diversificar seus investimentos em ações em geografias diferentes. Por isso, não é recomendável alocar 100% em ações brasileiras.',
							children: [
								{
									color: '#33a789',
									name: 'ETFs Ações Brasil',
									disclaimer: 'Fundos de índice do mercado brasileiro.',
								},

								{
									color: '#33a789',
									name: 'Carteira Própria',
									disclaimer: 'Use esta categoria para selecionar suas ações prediletas por conta própria (stock picking).',
								},

								{
									color: '#33a789',
									name: 'Fundos de Investimentos em Ações',
									disclaimer: 'FIAs que investem exclusivamente no brasil.',
								},
							]
						},
						{
							name: 'Internacional',
							color: '#1338d1',
							disclaimer: 'Como referência, é inteligente diversificar seus investimentos em ações em geografias diferentes. Por isso, não é recomendável alocar 100% em ações internacionais.',
							children: [
								{
									color: '#425fda',
									name: 'ETFS Ações Global Markets',
									disclaimer: 'Fundos de índice de mercados globais.',
								},

								{
									color: '#425fda',
									name: 'Stocks & BDRs',
									disclaimer: 'Use esta categoria para selecionar suas ações prediletas no exterior por conta própria (stock picking).',
								},

								{
									color: '#425fda',
									name: 'Fundos de Ações Global Markets',
									disclaimer: 'Fundos que investem preponderantemente em mercados globais.',
								},
							]
						}
					]
				},
				{
					name: 'Imóveis',
					color: '#00cc99',
					disclaimer: 'Sua alocação em imóveis irá contribuir para aumentar a rentabilidade da sua carteira. Como referência, lembre-se que é adequado para o investidor individual médio ter mais de 50% do percentual de renda variável distribuído entre ações e imóveis.',
					children: [
						{
							name: 'Brasil',
							color: '#00926c',
							disclaimer: 'Como referência, é inteligente diversificar seus investimentos em imóveis em geografias diferentes. É comum no Brasil, investidores começarem com 100% de alocação em imóveis em território nacional.',
							children: [
								{
									name: 'ETFs Imóveis Brasil',
									color: '#00664b',
									disclaimer: 'Fundos de índice do mercado imobiliário brasileiro.',
								},

								{
									name: 'Fundos Imobiliários',
									color: '#00664b',
									disclaimer: 'Use esta categoria para selecionar seus fundos imobiliários por conta própria. ',
								}
							]
						},
						{
							name: 'Exterior',
							color: '#637699',
							disclaimer: 'Use esta categoria para diversificar seus investimentos imobiliários em outras geografias.',
							children: [
								{
									name: 'ETFs Imóveis Global Markets',
									color: '#8ea9db',
									disclaimer: 'Fundos de índice de mercados imobiliários globais.',
								},

								{
									name: 'REITs',
									color: '#8ea9db',
									disclaimer: 'Use esta categoria para selecionar seus REITs (Real Estate Investment Trusts) prediletos no exterior por conta própria.',
								},
							]
						}
					]
				},
				{
					name: 'Proteção',
					color: '#ed8b0b',
					aviso: 'Para proteção e diversificação da carteira de investimentos, não é recomendável ultrapassar uma exposição de 15% em ativos de proteção, como dólar, ouro e  outras moedas e metais.',
					disclaimer: 'Sua alocação em ativos de proteção irá contribuir para descorrelacionar sua carteira, tornando-a mais resistente a momentos de instabilidade. Como referência, alocações de até 10% nesta categoria são consideradas adequadas para investidores individuais.',
					children: [
						{
							name: 'Dólar',
							color: '#ed8b0b',
							disclaimer: 'Investimentos em fundos cambiais e dólar',
						},
						{
							name: 'Ouro',
							color: '#ed8b0b',
							disclaimer: 'Fundos de ouro e ETFs de ouro.',
						},
						{
							name: 'Outras Moedas e Metais',
							color: '#ed8b0b',
							disclaimer: 'Fundos cambiais e outras moedas, como prata.',
						},
					]
				},
				{
					name: 'Criptoativos',
					color: '#efbc64',
					aviso: 'Para proteção e diversificação da carteira de investimentos, não é recomendável ultrapassar uma exposição de 10% em criptoativos.',
					disclaimer: 'Como investimento, as criptomoedas podem contribuir para turbinar sua rentabilidade. Contudo, ressalta-se a característica de volatilidade destes ativos. Como referência, alocações de até 5% nesta categoria são consideradas adequadas para investidores individuais.',
					children: [
						{
							name: 'Bitcoin & outras criptomoedas',
							color: '#efbc64',
							disclaimer: 'Use esta categoria para selecionar suas criptos prediletas por conta própria. ',
						},
						{
							name: 'ETFs e Fundos de Criptoativos',
							color: '#efbc64',
							disclaimer: 'Fundos de índice e fundos de investimentos de criptomoedas.',
						},
					]
				},
				{
					name: 'Fundos MMs & Outros',
					color: '#ca0023',
					disclaimer: 'Use esta categoria para acessar fundos multimercados, fundos temáticos e derivativos. Em tempo, destacamos que o uso de derivativos é pouco adequado para investidores individuais em geral.',
					children: [
						{
							name: 'Fundo Multimercado',
							color: '#ca0023',
							disclaimer: 'Use esta categoria para acessar Fundos de Investimentos Multiestratégia.',
						},

						{
							name: 'Fundos Temáticos',
							color: '#ca0023',
							disclaimer: 'Os fundos temáticos permitem que você acesse teses específicas, como por exemplo o investimento em empresas de tecnologia, ESG ou até mesmo a indústria de cannabis. ',
						},

						{
							name: 'Derivativos e Outros',
							color: '#ca0023',
							disclaimer: 'Use esta categoria para outros investimentos não especificados até o momento, ou para derivativos de qualquer natureza.',
						}
					],
				}
			]
		},
	]
};
