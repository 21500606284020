<p>
	Se você ainda não possui nenhum valor investido, clique apenas em "Próxima Etapa".
</p>
<div class="scroll">
	<table class="table">
		<thead>
			<tr>
				<td>
					Classe do Ativo
				</td>
				<td>
					Descrição
				</td>
				<td>Valor Atual</td>
				<td></td>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let ativo of ativos.controls; let i= index">
				<td style="min-width: 420px;">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item" *ngFor="let item of ativo.controls.name.value.split('_')">
								<a>{{item}}</a>
							</li>
						</ol>
					</nav>
				</td>
				<td style="width: 25%; min-width: 240px;">
					<input autofocus type="text" maxlength="128" placeholder="Descreva o ativo" autofocus
						[formControl]="ativo.controls.descricao">
				</td>
				<td style="width: 25%; min-width: 180px;">
					<div style="position: relative; padding-left: 12px; ">
						<div class="text">R$</div>
						<input class="form-control-left" type="text" name="money"
							[brmasker]="{money: true, thousand: '.',  decimalCaracter: ',', decimal: '2', type: 'num', form: ativo.controls.valorBr}"
							[formControl]="ativo.controls.valorBr">
					</div>
				</td>
				<td>
					<button style="margin-top: 2px; margin-bottom: 2px;" (click)="remove(i)" mat-icon-button
						aria-label="Remover">
						<mat-icon>close</mat-icon>

					</button>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<mat-menu #menuAtivos="matMenu">
						<ng-container *ngFor="let child of configuration.children">
							<button mat-menu-item [matMenuTriggerFor]="sub_menu">{{ child.name }}</button>
							<mat-menu #sub_menu="matMenu">
								<ng-container *ngFor="let childTwo of child.children">
									<button [matMenuTriggerFor]="subChild" *ngIf="childTwo?.children?.length > 0"
										mat-menu-item>{{ childTwo.name }}</button>
									<button *ngIf="childTwo.children == undefined"
										(click)="addAtivo([child.name,childTwo.name].join('_'))" mat-menu-item>{{
										childTwo.name }}</button>
									<mat-menu #subChild="matMenu">
										<ng-container *ngFor="let childTree of childTwo.children">
											<button [matMenuTriggerFor]="childOther"
												*ngIf="childTree?.children?.length > 0" mat-menu-item>{{ childTree.name
												}}</button>
											<button *ngIf="childTree.children == undefined"
												(click)="addAtivo([child.name,childTwo.name,childTree.name].join('_'))"
												mat-menu-item>{{ childTree.name }}</button>
											<mat-menu #childOther="matMenu">
												<ng-container *ngFor="let childFour of childTree.children">
													<button [matMenuTriggerFor]="child"
														*ngIf="childFour?.children?.length > 0" mat-menu-item>{{
														childFour.name }}</button>
													<button
														(click)="addAtivo([child.name,childTwo.name,childTree.name, childFour.name].join('_'))"
														*ngIf="childFour.children == undefined" mat-menu-item>{{
														childFour.name }}</button>
												</ng-container>
											</mat-menu>
										</ng-container>
									</mat-menu>
								</ng-container>
							</mat-menu>
						</ng-container>
					</mat-menu>



					<button [matMenuTriggerFor]="menuAtivos" mat-stroked-button color="primary">
						<mat-icon>add</mat-icon>
						Adicionar Ativo
					</button>
				</td>
				<td style="line-height: 64px; margin-right: 10px;">
					Total: <b> {{somaTotal | currency:'BRL':true:'1.0-2'}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
				</td>
			</tr>
		</tbody>
	</table>
</div>