import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { groupBy } from '../../oraculo/utils';
import { map } from 'rxjs/operators';
import { BrMaskDirective, BrMaskModel } from 'br-mask';


@Component({
  selector: 'app-ativos-atuais',
  templateUrl: './ativos-atuais.component.html',
  styleUrls: ['./ativos-atuais.component.scss']
})
export class AtivosAtuaisComponent implements OnInit {

  ativos: FormArray = new FormArray([]);

  @Input('values')
  values: any;

  @Input('configuration')
  configuration: any;

  @Output('changeAtivos')
  changeAtivos: EventEmitter<any> = new EventEmitter();

  @Output('removeItem')
  removeItem: EventEmitter<any> = new EventEmitter();

  constructor(public brMask: BrMaskDirective, ) { }

  ngOnInit(): void {

    if (this.values != undefined) {
      this.buildInitialValues();
    }
    let listLegacy = [];
    this.ativos.valueChanges.subscribe((value) => {
      if (this.ativos.valid) {
        let list = groupBy(this.ativos.value, 'name');
        listLegacy.filter((item) => !list.find((litem) => litem.key == item.key))
          .forEach((item) => this.removeItem.emit(item.key))
        listLegacy = list;
        list.forEach((item) => {

          this.emitAndSafeGuard(
            {
              [item.key]: item.values.map((ativo) => {
                ativo.valorAtual = Number((ativo.valorBr || '0').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace(',', '.'));
                return new Object({ name: item.key, ...ativo });
              }),
            }
          );


        });
        return;
      }
      this.changeAtivos.emit(null);
    });
  }
  emitAndSafeGuard(itens) {
    this.changeAtivos.emit(itens);
  }
  buildInitialValues() {
    Object.keys(this.values)
      .forEach((key) => {
        let value = this.values[key].forEach((value) => {
          const valorBr = new FormControl(this.createValueWithMask(value.valorAtual));
          console.log('valorBr', valorBr);
          const group = new FormGroup({
            name: new FormControl(value.name, [Validators.minLength(1)]),
            descricao: new FormControl(value.descricao),
            valorAtual: new FormControl(value.valorAtual, [Validators.minLength(1)]),
            valorBr: valorBr,
          });
          valorBr.valueChanges.pipe(
            map((item) => {
              return Number((item || '0').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace(',', '.'));
            }),
          )
            .subscribe((value) => {
              group.get('valorAtual').setValue(value)
            });
          this.ativos.push(
            group
          );
        });
      });
  }
  addAtivo(name: string) {
    const valorBr = new FormControl();
    const group = new FormGroup({
      name: new FormControl(name, [Validators.minLength(1)]),
      descricao: new FormControl(),
      valorAtual: new FormControl(0),
      valorBr: valorBr,
    });
    valorBr.valueChanges.pipe(
      map((item) => {
        return Number((item || '0').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace('.', '').replace(',', '.'));
      }),
    )
      .subscribe((value) => {
        group.get('valorAtual').setValue(value)
      });

    this.ativos.push(
      group
    );
  }
  remove(ativo: number) {
    this.ativos.removeAt(ativo);
  }
  get somaTotal() {
    return this.ativos?.controls.reduce((prev, current) => {
      return prev + current.value.valorAtual;
    }, 0);
  }
  private createValueWithMask(value): string {
    let intl = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
    return (intl.format(value) as any).replace('R$ ');
  }
}
