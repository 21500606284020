import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrMaskDirective, BrMaskerModule } from 'br-mask';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback/callback.component';
import { CallbackFolderComponent } from './callback-folder/callback-folder.component';
import { DefaultModule } from './layouts/default/default.module';
import { ModalStartComponent } from './modal-start/modal-start.component';
import { ArvoreAlocacaoComponent } from './modules/arvore-alocacao/arvore-alocacao.component';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { InicioComponent } from './modules/inicio/inicio.component';
import { LoginComponent } from './modules/login/login.component';
import { OraculoComponent } from './modules/oraculo/oraculo.component';
import { AtivosAtuaisComponent } from './modules/portolio-atual/ativos-atuais/ativos-atuais.component';
import { PortolioAtualComponent } from './modules/portolio-atual/portolio-atual.component';
import { PercentageEditorComponent } from './modules/portolio-configuracao/percentage-editor/percentage-editor.component';
import { PortolioConfiguracaoComponent } from './modules/portolio-configuracao/portolio-configuracao.component';
import { AtivosMetaComponent } from './modules/portolio-meta/ativos-meta/ativos-meta.component';
import { PortolioMetaComponent } from './modules/portolio-meta/portolio-meta.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { AuthGuard } from './shared/auth-guard.service';
import { ItemActionComponent } from './shared/components/item-action/item-action.component';
import { NgxTabsModule } from './shared/components/tabs/ngx-tabs.module';
import { DividaPipe } from './shared/divida.pipe';
import { MilionMoneyPipe } from './shared/milion-money.pipe';
import { NormalMoneyPipe } from './shared/normal-money.pipe';
import { SharedModule } from './shared/shared.module';
import { configureAuth } from './auth.config';
import { ReportsService } from './modules/inicio/reports.service';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { NgxPermissionsModule } from 'ngx-permissions';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    LoginComponent,
    CadastroComponent,
    NormalMoneyPipe,
    MilionMoneyPipe,
    DividaPipe,
    ModalStartComponent,
    ItemActionComponent, RecoveryPasswordComponent, InicioComponent, OraculoComponent, PortolioAtualComponent, PortolioMetaComponent, PortolioConfiguracaoComponent, PercentageEditorComponent, AtivosMetaComponent, AtivosAtuaisComponent, ArvoreAlocacaoComponent,
    CallbackComponent,
    CallbackFolderComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ChartsModule,
    BrMaskerModule,
    NgxTabsModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    HighchartsChartModule,
    NgxPaginationModule,
    MatSliderModule,
    MatStepperModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AuthModule.forRoot(),
    NgxPermissionsModule.forRoot()
  ],
  providers: [AuthGuard,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    ReportsService,
    BrMaskDirective,
    OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAuth,
            deps: [OidcConfigService],
            multi: true,
        },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
