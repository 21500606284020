export function buildChartData(ativos: any) {
    ativos.value = 100;
    ativos.name = '';
    let children = [
        ...getChildrenAndAtivo(ativos.children[0], ''),
        ...getChildrenAndAtivo(ativos.children[1], ''),
    ];
    return children;
    return [
        {
            id: 'renda_fixa',
            parent: '0',
            value: 20,
        },
        {
            parent: '0',
            id: 'renda_variavel',
            value: 10,
        }
    ];
}


export function getChildrenAndAtivo(ativo, chave) {
    let listAtivos = [];
    listAtivos.push({
        id: chave + '_' + ativo.name,
        name: ativo.name,
        parent: chave,
        value: ativo.value,
    });

    if (ativo.children == undefined) {
        return listAtivos;
    }
    return ativo.children.reduce((prev, item) => {
        return [...prev, ...getChildrenAndAtivo(item, chave + '_' + ativo.name)];
    }, listAtivos)
        .filter((item) => item.value != null);
}

export function formatarValor(valor: number, moeda: boolean = true, simboloMil: string = 'mil', simboloMilhao: string = 'mi', simboloBilhao: string = 'bi', maximoCasasDecimais: number = 2) {
    if (valor) {
        const valorAbsoluto = Math.abs(valor);
        let divisor: number = 1.0;
        let simbolo: string = '';

        if (valorAbsoluto >= 1000000000.0) {
            divisor = 1000000000.0;
            simbolo = ' ' + simboloBilhao;
        } else if (valorAbsoluto >= 1000000.0) {
            divisor = 1000000.0;
            simbolo = ' ' + simboloMilhao;
        } else if (valorAbsoluto >= 1000.0) {
            divisor = 1000.0;
            simbolo = ' ' + simboloMil;
        }

        let estilo: Intl.NumberFormatOptions;
        if (moeda) {
            estilo = { style: 'currency', currency: 'BRL', minimumFractionDigits: 1, maximumFractionDigits: 2 };
        } else {
            estilo = { style: 'decimal', maximumFractionDigits: maximoCasasDecimais };
        }

        let novoValor: number = valor / divisor;
        return `${novoValor.toLocaleString('pt-BR', estilo)}${simbolo}`;
    } else {
        return '-';
    }
}