<div *ngIf="configuration" class="card-arvore">
    <div class="heading-internal" style="margin-bottom: 0px">
        <div class="heading-surface"></div>
        <h2 class="heading-content" style="flex: 1;">
            Sua Árvore de Alocação:
            <span
                matTooltip="A soma de todos seu portfolio atual, lembre-se você pode alterar esse valor para fazer simulações clicando no iconde de editar."
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                        fill="black" />
                </svg>
            </span>
        </h2>
        <div class="heading-ri" style="margin-right: 24px;">
            <mat-button-toggle-group [(ngModel)]="mode">
                <mat-button-toggle value="arvore">
                    <mat-icon>account_tree</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="card">
                    <mat-icon>dashboard</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="card-content">
        <h5>Saldo total da sua Carteira:</h5>
        <div *ngIf="edit==false" class="saldo" (dblclick)="edit=true">R$
            {{valorTotalFormControl?.value?.replace('R$', '')}}
            <div class="align">
                <button (click)="edit=true" mat-icon-button aria-label="Clique para editar o saldo total">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="edit==true" class="saldo-form">
            <mat-form-field appearance="fill">
                <mat-label>Saldo total: </mat-label>
                <input matInput type="text" class="example-right-align"
                    [brmasker]="{money: true, thousand: '.',  decimalCaracter: ',', form: valorTotalFormControl}"
                    autofocus [formControl]="valorTotalFormControl">
                <span matPrefix><b>R$&nbsp;&nbsp;</b></span>
            </mat-form-field>
            <button mat-flat-button color="secondary" (click)="edit=false">Salvar</button>
        </div>
        <hr style="margin-top: 24px;">
        <div *ngIf="mode=='arvore'" class="card-decision">
            <figure>
                <ul style="margin-top: 36px;" class="tree">
                    <li>
                        <span style="min-width: 240px;">
                            <div class="name" style="
                            font-size: 18px;
                            padding: 12px;
                        ">Seu Portfólio Meta</div>
                            <div class="flex">
                                <div *ngIf="valorTotalFormControl" class="money" style="text-align: center;">

                                    <b>R$
                                        {{valorTotalFormControl?.value?.replace('R$', '')}} </b> (100%) <button
                                        style="margin: -10px; margin-left: 4px;" (click)="edit=true" mat-icon-button
                                        aria-label="Clique para editar o saldo total">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </span>
                        <ul>
                            <ng-container *ngFor="let child of configuration.children">
                                <ng-container *ngIf="child.value > 0">
                                    <li>
                                        <span>
                                            <div class="name" [style.borderLeftColor]="child.color">
                                                {{child.name}}</div>
                                            <div class="flex">
                                                <div class="percentage-item">
                                                    {{(child.value /100)|percent:'1.1'}}
                                                </div>
                                                <div class="money">
                                                    {{(child.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                </div>
                                            </div>
                                        </span>
                                        <ul>
                                            <ng-container *ngFor="let childTwo of child.children">
                                                <ng-container>
                                                    <ng-container *ngIf="childTwo.value > 0">
                                                        <li>
                                                            <span>
                                                                <div class="name"
                                                                    [style.borderLeftColor]="childTwo.color">
                                                                    {{childTwo.name}}
                                                                    <div *ngIf="childTwo.children" class="heading-right"
                                                                        style="padding-right: 12px; float: right; margin: -10px;">
                                                                        <button *ngIf="childTwo.open != true"
                                                                            (click)="childTwo.open=true" mat-icon-button
                                                                            aria-label="Clique para abrir a árvore.">
                                                                            <mat-icon>expand_more</mat-icon>
                                                                        </button>
                                                                        <button *ngIf="childTwo.open == true"
                                                                            (click)="childTwo.open=false"
                                                                            mat-icon-button
                                                                            aria-label="Clique para abrir a árvore.">
                                                                            <mat-icon>expand_less</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="flex">
                                                                    <div class="percentage-item">
                                                                        {{(childTwo.value /100)|percent:'1.1'}}

                                                                    </div>
                                                                    <div class="money">
                                                                        {{((child.value/100) * childTwo.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <ul *ngIf="childTwo.open==true">
                                                                <ng-container
                                                                    *ngFor="let childTree of childTwo.children">
                                                                    <ng-container>
                                                                        <ng-container *ngIf="childTree.value > 0">
                                                                            <li name="nivel2">
                                                                                <ng-container>
                                                                                    <span>
                                                                                        <div class="name"
                                                                                            [style.borderLeftColor]="childTree.color">
                                                                                            {{childTree.name}}
                                                                                        </div>
                                                                                        <div class="flex">
                                                                                            <div
                                                                                                class="percentage-item">
                                                                                                {{(childTree.value /100)|percent:'1.1'}}
                                                                                            </div>
                                                                                            <div class="money">
                                                                                                {{((childTree.value/100) *(child.value/100) * childTwo.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </ng-container>

                                                                                <ng-container
                                                                                    *ngIf="childTree.children != undefined ">
                                                                                    <ul>
                                                                                        <ng-container
                                                                                            *ngFor="let childFour of childTree.children">
                                                                                            <ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="childFour.value > 0">
                                                                                                    <li><span>
                                                                                                            <div class="name"
                                                                                                                [style.borderLeftColor]="childFour.color">
                                                                                                                {{childFour.name}}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="flex">
                                                                                                                <div
                                                                                                                    class="percentage-item">
                                                                                                                    {{(childFour.value /100)|percent:'1.1'}}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="money">
                                                                                                                    {{((childFour.value/100) *(childTree.value/100) *(child.value/100) * childTwo.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </li>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                    </ul>
                                                                                </ng-container>

                                                                            </li>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ul>
                                                        </li>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </li>
                </ul>
            </figure>

        </div>
        <div *ngIf="mode=='card'" class="progress">
            <ng-container *ngFor="let child of configuration.children">
                <ng-container *ngIf="child.value > 0">
                    <div class="progress-bar" [style.width]="child.value + '%'" [style.background]="child.color">
                        {{child.name}} - {{(child.value /100)|percent:'1.1'}}
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="mode=='card'" class="row">
            <ng-container *ngFor="let child of configuration.children">
                <ng-container *ngIf="child.value > 0">
                    <div class="col">
                        <div class="card-arvore">
                            <div class="heading-internal" style="margin-bottom: 0px">
                                <div class="heading-surface" [style.background]="child.color"></div>
                                <h2 class="heading-content">
                                    {{child.name}}
                                    <span
                                        matTooltip="A soma de todos seu portfolio atual, lembre-se você pode alterar esse valor para fazer simulações clicando no iconde de editar."
                                        content-type="template">
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                                fill="black" />
                                        </svg>
                                    </span>
                                </h2>
                            </div>
                            <div class="card-content">
                                <span
                                    style="font-size: 21px;color: #666;font-weight: 600;">{{(child.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                    <span
                                        style="font-size: 12px;font-weight: normal;">({{(child.value /100)|percent:'1.1'}})</span></span>
                                <hr>

                                <div class="progress">
                                    <ng-container *ngFor="let childChart of child.children">
                                        <ng-container *ngIf="childChart.value > 0">
                                            <div class="progress-bar" [style.width]="childChart.value + '%'"
                                                [style.background]="childChart.color"
                                                matTooltip="{{(childChart.value /100)|percent:'1.1'}}">
                                                {{childChart.name}}
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                        </div>
                        <div class="sub-cards">
                            <ng-container *ngFor="let childTwo of child.children">
                                <ng-container *ngIf="childTwo.value > 0">
                                    <div class="card-left">
                                        <div class="card-arvore">
                                            <div class="heading-internal" style="margin-bottom: 0px">
                                                <div class="heading-surface" [style.background]="childTwo.color"></div>
                                                <h2 class="heading-content" style="flex: 1;">
                                                    {{childTwo.name}}
                                                    <span
                                                        matTooltip="A soma de todos seu portfolio atual, lembre-se você pode alterar esse valor para fazer simulações clicando no iconde de editar."
                                                        content-type="template">
                                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                                                fill="black" />
                                                        </svg>
                                                    </span>
                                                </h2>
                                                <div *ngIf="childTwo.children" class="heading-right"
                                                    style="padding-right: 12px;">
                                                    <button *ngIf="childTwo.open != true" (click)="childTwo.open=true"
                                                        mat-icon-button aria-label="Clique para abrir a árvore.">
                                                        <mat-icon>expand_more</mat-icon>
                                                    </button>
                                                    <button *ngIf="childTwo.open == true" (click)="childTwo.open=false"
                                                        mat-icon-button aria-label="Clique para abrir a árvore.">
                                                        <mat-icon>expand_less</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-content">
                                                <span
                                                    style="font-size: 21px;color: #666;font-weight: 600;">{{((child.value/100) * childTwo.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                    <span
                                                        style="font-size: 12px;font-weight: normal;">({{(childTwo.value /100)|percent:'1.1'}})</span></span>
                                                <hr>

                                                <div *ngIf=" childTwo.children" class="progress">
                                                    <ng-container *ngFor="let childChart of childTwo.children">
                                                        <ng-container *ngIf="childChart.value > 0">
                                                            <div class="progress-bar"
                                                                [style.width]="childChart.value + '%'"
                                                                [style.background]="childChart.color"
                                                                matTooltip="{{(childChart.value /100)|percent:'1.1'}}">
                                                                {{childChart.name}}
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>



                                        </div>
                                        <!--
                                                                                                        Divisor
                                                                                                    -->

                                        <div *ngIf="childTwo.open == true && childTwo.children" class="sub-cards">
                                            <ng-container *ngFor="let childTree of childTwo.children">
                                                <ng-container *ngIf="childTree.value > 0">
                                                    <div class="card-left">
                                                        <div class="card-arvore">
                                                            <div class="heading-internal" style="margin-bottom: 0px">
                                                                <div class="heading-surface"
                                                                    [style.background]="childTree.color"></div>
                                                                <h2 class="heading-content">
                                                                    {{childTree.name}}
                                                                    <span
                                                                        matTooltip="A soma de todos seu portfolio atual, lembre-se você pode alterar esse valor para fazer simulações clicando no iconde de editar."
                                                                        content-type="template">
                                                                        <svg width="17" height="17" viewBox="0 0 17 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                                                                fill="black" />
                                                                        </svg>
                                                                    </span>
                                                                </h2>
                                                            </div>
                                                            <div class="card-content">
                                                                <span
                                                                    style="font-size: 21px;color: #666;font-weight: 600;">{{((childTree.value/100) *(child.value/100) * childTwo.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                                    <span
                                                                        style="font-size: 12px;font-weight: normal;">({{(childTree.value /100)|percent:'1.1'}})</span></span>
                                                                <hr>
                                                                <div *ngIf="childTree.children" class="progress">
                                                                    <ng-container
                                                                        *ngFor="let childChart of childTree.children">
                                                                        <ng-container *ngIf="childChart.value > 0">
                                                                            <div class="progress-bar"
                                                                                [style.width]="childChart.value + '%'"
                                                                                [style.background]="childChart.color"
                                                                                matTooltip="{{(childChart.value /100)|percent:'1.1'}}">
                                                                                {{childChart.name}}
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="childTree.children" class="sub-cards">
                                                            <ng-container *ngFor="let childFour of childTree.children">
                                                                <ng-container *ngIf="childFour.value > 0">
                                                                    <div class="card-left">
                                                                        <div class="card-arvore">
                                                                            <div class="heading-internal"
                                                                                style="margin-bottom: 0px">
                                                                                <div class="heading-surface"
                                                                                    [style.background]="childFour.color">
                                                                                </div>
                                                                                <h2 class="heading-content">
                                                                                    {{childFour.name}}
                                                                                    <span
                                                                                        matTooltip="A soma de todos seu portfolio atual, lembre-se você pode alterar esse valor para fazer simulações clicando no iconde de editar."
                                                                                        content-type="template">
                                                                                        <svg width="17" height="17"
                                                                                            viewBox="0 0 17 17"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                                                                                                fill="black" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </h2>
                                                                            </div>
                                                                            <div class="card-content">
                                                                                <span
                                                                                    style="font-size: 21px;color: #666;font-weight: 600;">{{((childFour.value/100) *(childTree.value/100) *(child.value/100) * childTwo.value /100) * valorTotal | currency:'BRL':true:'1.0-2'}}
                                                                                    <span
                                                                                        style="font-size: 12px;font-weight: normal;">({{(childFour.value /100)|percent:'1.1'}})</span></span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </div>
    </div>
</div>