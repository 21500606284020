<p>
    Decida os ativos desejados e preencha com sua porcentagem:
</p>
<table class="table">
    <thead>
        <tr>
            <td>
                Nome do Ativo
            </td>
            <td>Descrição do Ativo</td>
            <td>Porcentagem desejada</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ativo of ativos.controls">
            <td>
                <select name="names" [formControl]="ativo.controls.name">
                    <option *ngFor="let option of options" [value]="option">{{option}}</option>

                </select>
            </td>
            <td>
                <input type="text" [formControl]="ativo.controls.descricao">
            </td>
            <td>

                <input type="number" [formControl]="ativo.controls.percentageMeta">
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <button (click)="addAtivo()" mat-stroked-button color="primary">
                    <mat-icon>add</mat-icon>
                    Adicionar Ativo
                </button>
            </td>
            <td style="line-height: 42px;">
                Total: {{somaTotal}}%
            </td>
        </tr>
    </tbody>
</table>