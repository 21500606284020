import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OraculoService } from '../../shared/oraculo.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { configurationAssets } from './../portolio-configuracao/configuration';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portolio-meta',
  templateUrl: './portolio-meta.component.html',
  styleUrls: ['./portolio-meta.component.scss']
})
export class PortolioMetaComponent implements OnInit {

  configuration = configurationAssets;
  configurationGroup: FormGroup;
  ativosFinais: any = {};
  loading = true;

  constructor(private cdr: ChangeDetectorRef, public oraculoService: OraculoService, public router: Router, ) { }

  ngOnInit(): void {
    this.configurationGroup = (this.buildConfiguration(this.configuration));
    this.configurationGroup.valueChanges.subscribe((data) => this.cdr.detectChanges());



  }

  onSave() {
    this.oraculoService.setStepper('meta', true)
      .subscribe((data) => {
        this.router.navigate(['/inicio']);
      });
  }
  buildConfiguration(configuration: any) {
    let formGroup = new FormGroup({
      name: new FormControl(configuration.name),
      value: new FormControl(),
    }, {
      validators: (fn) => {

        if (fn.get('value').value == null) return null;
        if (fn.get('children') == undefined) return null;
        let sumChildren = (fn.get('children') as any).controls.map((control) => control.value.value).filter((item) => Number(item)).reduce((prev, curr) => Number(prev) + Number(curr), 0);

        if (sumChildren == 100) {
          return null;
        }
        return { totalEqual100: true };
      }
    });
    if (configuration.children != undefined) {
      formGroup.addControl('children', new FormArray(configuration.children.map((child) => this.buildConfiguration(child))));
    }
    return formGroup;
  }
  updateAtivos(value: any) {
    console.log('value', value);
    this.ativosFinais = {
      ...this.ativosFinais,
      ...value,
    };
    console.log('updateAtivos', this.ativosFinais);
  }
}
