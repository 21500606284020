<app-onboarding class="meta" [requiriments]="['carteira']">
    <h4 class="title" style="margin-top: 8px;">Portfólio meta:</h4>
    <div class="row">
        <div class="col-md-4">
            <div class="card-decision">
                Renda fixa
            </div>
        </div>
        <div class="col-md-8">

        </div>
    </div>
    <div *ngIf="loading==false">
        <div *ngFor="let child of configurationGroup.get('children')?.controls">
            <h5 class="sub-title">
                Configure seu portfólio meta de <b>{{child.value.name}}</b>
            </h5>
            <ng-container *ngFor="let childTwo of child.get('children')?.controls">
                <div *ngIf="childTwo.get('value').value>0">
                    <div>
                        <div class="card-decision">
                            <h5 class="subtree-title">
                                <b>{{childTwo.value.name}}</b>
                            </h5>
                            <p>Navegue entre as opções abaixo para preencher seu portfólio de
                                <b>{{childTwo.value.name}}</b></p>
                            <ngx-tabs>
                                <ng-container *ngFor="let childTree of childTwo.get('children')?.controls">

                                    <ngx-tab *ngIf="childTree.get('value').value>0"
                                        [name]="childTree.get('name').value">

                                        <div>
                                            <div class="card-decision">
                                                <h5 class="subtree-main-title">
                                                    {{childTree.value.name}}
                                                </h5>
                                                <div
                                                    *ngIf="childTree.get('children')?.controls == null && childTree.value.value > 0">
                                                    <app-ativos-meta
                                                        [values]="ativosFinais[child.value.name + '_' + childTwo.value.name+ '_' + childTree.value.name]"
                                                        [name]="child.value.name + '_' + childTwo.value.name+ '_' + childTree.value.name"
                                                        (changeAtivos)="updateAtivos($event)"></app-ativos-meta>
                                                </div>

                                                <ng-container
                                                    *ngFor="let childFour of childTree.get('children')?.controls">

                                                    <div *ngIf="childFour.value.value > 0">
                                                        <h5 class="subtree-title">
                                                            {{childFour.value.name}}
                                                        </h5>
                                                        <div>
                                                            <app-ativos-meta
                                                                [name]="child.value.name + '_' + childTwo.value.name+ '_' + childTree.value.name+ '_' + childFour.value.name"
                                                                [values]="ativosFinais[child.value.name + '_' + childTwo.value.name+ '_' + childTree.value.name+ '_' + childFour.value.name]"
                                                                (changeAtivos)="updateAtivos($event)"></app-ativos-meta>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ngx-tab>
                                </ng-container>
                            </ngx-tabs>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!configurationGroup.valid"
        style="background-color: #109cf1; color: #fff; width: 100%; font-size: 18px; font-weight: bold; line-height: 60px; margin-top: 24px;">Próximo</button>

</app-onboarding>