import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'percentage-editor',
  templateUrl: './percentage-editor.component.html',
  styleUrls: ['./percentage-editor.component.scss']
})
export class PercentageEditorComponent implements OnInit {

  @Input('control')
  formControl: FormControl;
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  valueChange($event) {
    this.formControl.setValue($event.value);
    this.formControl.markAsTouched();

    this.cdr.detectChanges();
  }
}
