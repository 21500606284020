<div *ngIf="loading==true" class="loading">
    Carregando...
</div>
<div *ngIf="loading==false">
    <div *ngIf="!checked">
        <h4>
            Seja bem-vindo ao Sistema Oráculo
        </h4>
        <p style="line-height: 32px;">
            Através do nosso sistema você sairá na frente de 95% dos investidores no Brasil.
        </p>
        <ol>
            <li>Você terá uma estratégia clara de alocação de ativos;</li>
            <li>Você terá acesso ao rebalanceamento automático da sua carteira a cada novo aporte.</li>
        </ol>
        <p style="line-height: 32px;">

            Para começar, nós precisaremos definir qual será sua estratégia de alocação de ativos, em 3 etapas.

            Isso levará alguns minutos, mas quando você concluir, o trabalho passa a ser do algoritmo Oráculo.<Br>
        </p>
        <div class="md-stepper-horizontal orange">
            <div class="md-step done active" [ngClass]="{'done': checkeds?.carteira == true}">
                <div class="md-step-circle"><span>
                        <mat-icon matListIcon>assessment</mat-icon>
                    </span></div>
                <div class="md-step-title">1. Escolher meu Portfólio Meta</div>
                <div class="md-step-optional">
                    <button mat-raised-button [routerLink]="['/configurar-carteira']" color="Primary">Começar
                        agora</button>
                </div>
                <div class="md-step-bar-left"></div>
                <div class="md-step-bar-right"></div>
            </div>
            <div class="md-step" [ngClass]="{'active': checkeds?.carteira == true, 'done': checkeds?.atual == true}">
                <div class="md-step-circle"><span>
                        <mat-icon matListIcon>list</mat-icon>
                    </span></div>

                <div class="md-step-title">2. Especifique seu Portfólio Atual</div>
                <div class="md-step-optional">
                    <button mat-raised-button [routerLink]="['/portfolio-atual']" color="primary">Fazer agora</button>
                </div>
                <div class="md-step-bar-left"></div>
                <div class="md-step-bar-right"></div>
            </div>
            <div class="md-step" [ngClass]="{'active': checkeds?.atual == true}">
                <div class="md-step-circle"><span>
                        <mat-icon matListIcon>check_circle</mat-icon>
                    </span></div>

                <div class="md-step-title">3. Faça seu Rebalanceamento</div>
                <div class="md-step-optional">
                    <button mat-raised-button [routerLink]="['/oraculo']" color="primary">Fazer Agora</button>
                </div>
                <div class="md-step-bar-left"></div>
                <div class="md-step-bar-right"></div>
            </div>
        </div>
        <p style="line-height: 32px;">
            <p>
                Se você ainda não sabe como definir sua estratégia, pode selecionar um dos nossos portfólios sugeridos
                no
                menu à
                esquerda, clicando em “Estratégias de Referência, ou acessando o menu “Comece Por Aqui”.<br>

                Bons investimentos!
            </p>
    </div>
    <div *ngIf="checked">
        <ng-content></ng-content>
    </div>
</div>