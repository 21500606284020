import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthFolderService } from '../callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root'
})
export class OraculoService {
  keyCache = 'cacheble';
  constructor(
    public http: HttpClient,
    public oidcSecurityService: OidcSecurityService,
    public authFolderService: AuthFolderService,
  ) {
  }
  get token() {
    if(this.authFolderService.authenticated()){
      return this.authFolderService.getAccess();
    }
    return this.oidcSecurityService.getToken();
  }


  getOraculoInformation() {
    return zip(
      this.getAtivosAtuais(),
      this.getConfigurationMeta()
    ).pipe(
      map((data) => {
        let information = data[0];
        let valorTotal = Object.keys(information)
          .map((key) => {
            return Object.keys(information[key]).map((secondKey) => {
              return information[key][secondKey].valorAtual;
            })
              .filter((value) => value != undefined || value != null)
              .reduce((prev, curr) => {
                return prev + curr;
              }, 0);
          })
          .reduce((prev, curr) => {
            return prev + curr;
          }, 0);

        return {
          atuais: data[0],
          meta: {},
          information,
          valorTotal,
          configuration: data[1],
        };
      })
    );
  }

  verifySteppers(names: string[], ) {
    return this.getStepper().pipe(
      map((value) => {
        if (value == undefined) {
          value = {};
        }
        return names.every((key) => value[key] == true);
      })
    );
  }
  setStepper(name: string, enabled: boolean, ) {
    return this.getStepper().pipe(
      map((value) => {
        if (value == undefined) {
          value = {};
        }
        value[name] = enabled;
        this.saveStepper(value);
        return value;
      })
    );
  }

  // migrar
  getStepper() {
    return this.http.get(environment.url + 'oraculo/stepper', {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
  // migrar
  saveStepper(stepper: any, ) {
    return this.http.put(environment.url + 'oraculo/stepper', stepper, {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    }).subscribe((data) => console.log('save', data));

  }

  // migrar
  saveAtivosAtuais(ativos: any, ) {
    return this.http.put(environment.url + 'oraculo/ativos-atuais', ativos, {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
  // migrar
  getAtivosAtuais() {
    return this.http.get(environment.url + 'oraculo/ativos-atuais', {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
  // migrar
  saveConfigurationMeta(configuration: any, ) {
    return this.http.put(environment.url + 'oraculo/configuration', configuration, {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    }).subscribe((data) => console.log('save', data));

  }
  // migrar
  getConfigurationMeta() {
    return this.http.get(environment.url + 'oraculo/configuration', {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
}
