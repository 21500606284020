export function buildValue(keys, data, valorWithNewAporte) {
    let estruture: any = {
        percentageMeta: getPercentage(keys, data.configuration),
        valueAtual: getValueAtual(keys, data),
    };
    estruture.percentageObjetivo = estruture.percentageMeta.percentage;
    estruture.percentageCarteiraTotal = estruture.valueAtual / data.valorTotal;
    estruture.percentageObjetivoOnItem = estruture.percentageMeta.percentageOnItem;

    let valueTotalOnItem = getValueAtual(keys.filter((item, i) => i < (keys.length) - 1), data);
    let valueOnItem = estruture.valueAtual / valueTotalOnItem;
    valueOnItem = valueOnItem || 0;
    estruture.percentageCarteira = (estruture.valueAtual / valueTotalOnItem) || 0;
    estruture.diferencaOnItem = (((estruture.percentageObjetivoOnItem || 0) - valueOnItem) * -1) || 0;

    estruture.diferenca = (estruture.percentageObjetivo - estruture.percentageCarteiraTotal) * -1;
    estruture.objetivo = estruture.percentageMeta.percentage * valorWithNewAporte;
    estruture.falta = estruture.objetivo - estruture.valueAtual;
    return estruture;
}
export function getPercentage(keys, configuration) {
    return keys.reduce((prev: any, key) => {
        prev.configuration = prev.configuration.children.find((configuration) => configuration.name == key);
        let number = prev.configuration?.value ?? 0;
        prev.percentageOnItem = Number(prev.configuration?.value) / 100;
        prev.percentage = prev.percentage * (number / 100);
        return prev;
    }, {
        percentage: 1,
        percentageOnItem: 0,
        configuration,
    });
}
export function getValueAtual(keys, data) {
    return Object.keys(data.atuais)
        .map((key) => key.split('_'))
        .filter((keysList) => (keysList.filter((item, i) => i < keys.length) as any).equals(keys))
        .reduce((prevMain, keys: any) => {
            return data.atuais[keys.join('_')].reduce((prev, item) => prev + item.valorAtual, prevMain);
        }, 0);
}