<app-onboarding class="atual" [requiriments]="['carteira']">
    <h4 class="title" style="margin-top: 8px;">Portfólio Atual:</h4>
    <div *ngIf="loading==false">
        <div>
            <div>
                <div>
                    <div class="card-decision">
                        <h5 class="subtree-title">
                            <b>Caso já possua investimentos no Mercado Financeiro, descreva-os abaixo:</b>
                        </h5>
                        <app-ativos-atuais [configuration]="configuration" [values]="ativosFinais"
                            (changeAtivos)="updateAtivos($event)" (removeItem)="removeAtivo($event)">
                        </app-ativos-atuais>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <button mat-flat-button color="primary" (click)="onSave()"
        style="background-color: #109cf1; color: #fff; width: 100%; font-size: 18px; font-weight: bold; line-height: 60px; margin-top: 24px;">Próxima
        Etapa</button>
    <div *ngIf="haveZero" class="alert alert-primary" role="alert" style="    
        margin-top: 20px;
        color: #fff;
        background-color: #363636;
        border-color: #363636;">
        <mat-icon style="margin-left: 48%; 
        font-size: 35px;">error_outline</mat-icon>
        <h4 style="
        text-align: center;   
        font-size: 18px;
        font-weight: bold;
        color: #fff;">
            <strong>Atenção:</strong> Você precisa preencher o valor em R$ do seu portfolio atual.
        </h4>
    </div>
</app-onboarding>