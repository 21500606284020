import { Component, OnInit, KeyValueDiffers } from '@angular/core';
import { OraculoService } from '../../shared/oraculo.service';
import { colors } from './../portolio-configuracao/configuration';
import { FormControl } from '@angular/forms';
import { buildValue, getPercentage, getValueAtual } from './oraculo-calc.service';

@Component({
  selector: 'app-oraculo',
  templateUrl: './oraculo.component.html',
  styleUrls: ['./oraculo.component.scss']
})
export class OraculoComponent implements OnInit {

  novoAporte: FormControl = new FormControl();
  suggestions: any = [];
  configuration: any;
  loadingCalculo = true;
  data: any;
  open: boolean = false;
  color: any = colors;

  valorWithNewAporte: number = 0;
  origin: string = 'empreenderdinheiro';

  constructor(public oraculoService: OraculoService, ) {
    this.origin = this.getOrigin();
  }
  getOrigin(): string {    
    if ((location as any).href.toString().split('tf').length > 1) {
      return 'techfinance';
    }
    if ((location as any).href.toString().split('techfinance').length > 1) {
      return 'techfinance';
    }
    return 'empreenderdinheiro';
  }

  ngOnInit(): void {
    this.oraculoService.getOraculoInformation().subscribe((data) => {
      this.data = data;
      this.valorWithNewAporte = this.data.valorTotal;
      this.configuration = data.configuration;
      this.loadingCalculo = false;
    });
  }
  buildValue = buildValue;
  getPercentage = getPercentage;
  getValueAtual = getValueAtual;
  novoAporteSubmit() {
    this.loadingCalculo = true;
    setTimeout(() => {
      this.loadingCalculo = false;
      this.oraculoService.getOraculoInformation().subscribe((data) => {
        console.log('getOraculoInformation', data);
        this.data = data;
        this.valorWithNewAporte = this.data.valorTotal + this.novoAporte.value;
      });
    }, 5000);
  }

}
