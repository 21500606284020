<mat-toolbar color="primary">
	<mat-toolbar-row>
		<span style="margin-left: 24px; margin-right: 36px">
			<a *ngIf="origin=='empreenderdinheiro'" [href]="urls[origin]">
				<span class="d-none d-sm-block" style="margin-left: 10px; margin-right: 36px;">
				  <img src="/assets/images/logo_tf.png" style="max-width: 196px;" alt="">
				</span>
				<span class="d-block d-sm-none" style="margin-left: 5px; margin-right: 10px;">
				  <img src="/assets/images/50favicon.png" style="max-width: 32px;" alt="">
				</span>
			  </a>
			  <a *ngIf="origin=='techfinance'" [href]="urls[origin]">
				<span class="d-none d-sm-block" style="margin-left: 10px; margin-right: 36px;">
				  <img src="/assets/images/logo tf horizontal.png" style="max-width: 196px;" alt="">
				</span>
				<span class="d-block d-sm-none" style="margin-left: 5px; margin-right: 10px;">
				  <img src="/assets/images/tf_favicon.png" style="max-width: 196px;" alt="">
				</span>
			  </a>
		</span>
		<button mat-icon-button (click)="toggleSideBar()">
			<mat-icon>menu</mat-icon>
		</button>
		<div fxFlex fxLayout="row" fxLayoutAlign="flex-start">
			<img src="assets/images/logo_selectus.png" style="width: 120px; margin-left: 24px" />
		</div>
		<div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
			<ul fxLayout="row" fxLayoutGap="20px">
				<li>
					<button mat-button [matMenuTriggerFor]="menu">
						<mat-icon>exit_to_app</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button (click)="logout()" mat-menu-item>
							<mat-icon>exit_to_app</mat-icon>
							Sair
						</button>
					</mat-menu>
				</li>
			</ul>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
