<div class="content">
  <app-onboarding>
    <div *ngIf="configuration" class="total">
      <div class="row">
        <div class="col-md-8">
          <div class="title">
            <h2>Seja bem-vindo ao Sistema Oráculo!</h2>
            <p>
              Confira a distribuição gráfica de sua carteira de investimentos.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div style="text-align: right;">
            <button
              style="
            color: #fff;
            background: #363636;
        "
              mat-flat-button
              color="primary"
              [routerLink]="['/oraculo']"
            >
              <mat-icon matListIcon>tune</mat-icon>

              Quero fazer um rebalanceamento
            </button>

            <div *ngIf="loadingDownload == true" style="padding: 8px;">
              <mat-spinner
                style="float: right;"
                diameter="30"
                color="#ccc"
              ></mat-spinner>
            </div>
            <!-- <div *ngIf="loadingDownload == false">
							<button *ngxPermissionsOnly="['techfinance::access']" class="hide-print" mat-flat-button
								color="primary" (click)="downloadAction()"
								style=" margin-top: 8px;background: #00acc1;color: #fff; float: right;">
								Gerar relatório
								<span class="badge bg-secondary" style="background: #000; color: #fff;">BETA</span>
								<mat-icon>print</mat-icon>
							</button>
						</div>
					-->
          </div>
        </div>
      </div>
    </div>
    <app-arvore-alocacao
      [configuration]="configuration"
      [valorTotalFormControl]="valorTotalFormControl"
    >
    </app-arvore-alocacao>

    <div *ngIf="configuration">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="card-decision">
                <h5 class="subtree-title">
                  <b>Portfólio atual</b>
                </h5>
                <div class="chart" style="max-width: 520px; margin: 0 auto;">
                  <canvas
                    width="620px"
                    height="320px"
                    baseChart
                    [data]="carteiraAtualPercentage"
                    [labels]="labelsFirstChart"
                    chartType="doughnut"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    [colors]="pieChartColors"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card-decision">
                <h5 class="subtree-title">
                  <b>Portfólio atual de renda fixa:</b>
                </h5>
                <div style="display: block">
                  <canvas
                    width="620px"
                    height="320px"
                    baseChart
                    [data]="carteiraAtualRendaFixa.data"
                    [labels]="carteiraAtualRendaFixa.labels"
                    chartType="doughnut"
                    [options]="pieChartMoneyOptions"
                    [plugins]="pieChartPlugins"
                    [colors]="carteiraAtualRendaFixa.colors"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card-decision">
                <h5 class="subtree-title">
                  <b>Portfólio atual de renda variável</b>
                </h5>
                <div style="display: block">
                  <canvas
                    width="620px"
                    height="320px"
                    baseChart
                    [data]="carteiraAtualRendaVariavel.data"
                    [labels]="carteiraAtualRendaVariavel.labels"
                    chartType="doughnut"
                    [options]="pieChartMoneyOptions"
                    [plugins]="pieChartPlugins"
                    [colors]="carteiraAtualRendaVariavel.colors"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="card-decision">
                <h5 class="subtree-title">
                  <b>Portfólio Meta</b>
                </h5>
                <div class="chart" style="max-width: 520px; margin: 0 auto;">
                  <canvas
                    width="620px"
                    height="320px"
                    baseChart
                    [data]="[
                      configuration.children[0].value,
                      configuration.children[1].value
                    ]"
                    [labels]="labelsMetaChart"
                    chartType="doughnut"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    [colors]="pieChartColors"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card-decision">
                <h5 class="subtree-title">
                  <b>Portfólio meta de renda fixa</b>
                </h5>
                <div style="display: block">
                  <canvas
                    width="620px"
                    height="320px"
                    baseChart
                    [data]="metaRendaFixa.data"
                    [labels]="metaRendaFixa.labels"
                    chartType="doughnut"
                    [options]="pieChartPercentageOptions"
                    [plugins]="pieChartPlugins"
                    [colors]="metaRendaFixa.colors"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card-decision">
                <h5 class="subtree-title">
                  <b>Portfólio meta de renda variável</b>
                </h5>
                <div style="display: block">
                  <canvas
                    width="620px"
                    height="320px"
                    baseChart
                    [data]="metaRendaVariavel.data"
                    [labels]="metaRendaVariavel.labels"
                    chartType="doughnut"
                    [options]="pieChartPercentageOptions"
                    [plugins]="pieChartPlugins"
                    [colors]="metaRendaVariavel.colors"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-onboarding>
</div>
