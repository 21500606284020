<div *ngIf="formControl" class="row">
    <div class="col-5">

        <mat-form-field appearance="outline">
            <mat-label>Digite uma Porcentagem</mat-label>
            <input name="percentage-editor" min="0" max="100" matInput placeholder="Digite uma porcentagem"
                type="number" [formControl]="formControl">
            <div matSuffix>%</div>
        </mat-form-field>
    </div>
    <div class="col-7">
        <div style="
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-bottom: 7px;
">Ou selecione uma opção:</div>
        <mat-chip-list aria-label="Fish selection">
            <mat-chip (click)="formControl.setValue(10)" color="primary" [selected]="formControl.value == 10">10%
            </mat-chip>
            <mat-chip (click)="formControl.setValue(20)" color="primary" [selected]="formControl.value == 20">20%
            </mat-chip>
            <mat-chip (click)="formControl.setValue(30)" color="primary" [selected]="formControl.value == 30">30%
            </mat-chip>
            <mat-chip (click)="formControl.setValue(50)" color="primary" [selected]="formControl.value == 50">50%
            </mat-chip>
            <mat-chip (click)="formControl.setValue(100)" color="primary" [selected]="formControl.value == 100">100%
            </mat-chip>
        </mat-chip-list>
    </div>
</div>