import { Component, OnInit, Input } from '@angular/core';
import { OraculoService } from '../oraculo.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  @Input('requiriments')
  requiriments: string[] = ['atual', 'carteira'];
  checked: boolean = false;
  checkeds: any = {};
  loading = true;

  constructor(public oraculoService: OraculoService, ) {

  }

  ngOnInit(): void {
    this.oraculoService.verifySteppers(this.requiriments)
      .subscribe((data) => {
        this.loading = false;
        this.checked = data;
        this.oraculoService.getStepper().subscribe((checkeds) => {
          this.checkeds = checkeds;
        });
      });
  }

}
